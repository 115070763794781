import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DefaultModal from '../../../Modal/DefaultModal';
import RadioField from '../../../Input/RadioField';
import { cn } from '../../../../utils';
import { issuingAPI } from '../../../../services/Axios';
import FailAlert from '../../../Alert/FailAlert';
import Money from '../../../Money';
import { format, parse } from 'date-fns';

export default function TransferReasonModal({ isOpen, onClose, data }) {
  const { t } = useTranslation();
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag);
  const [reason, setReason] = useState(null);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('');

  const handleConfirmTransfer = () => {
    setStatus({ status: 'loading' });
    issuingAPI
      .post(`/tag/${selectedTag.id}/replacements`, {
        from: data.from.token,
        to: data.to.token,
        reason: reason.value,
      })
      .then((response) => {
        // Open the receipt modal with data provided
      })
      .catch((err) => {
        setStatus({ status: 'error', message: err });
      });
  };

  const ConfirmationMessage = () => {
    return (
      <div>
        {reason.fee !== 0 && (
          <p className={'mb-4'}>
            <Trans
              i18nKey="card-replacements.transfer-fee.message"
              values={{
                currency: tagCurrency,
                feeAmount: reason.fee,
              }}
              components={{ strong: <strong /> }}
            />
          </p>
        )}
        <span>{t('card-replacements.transfer-fee.subtitle')}:</span>
        <div className={'flex mt-2'}>
          <span className={'w-40'}>{t('Balance')}:</span>
          <span className={'font-bold'}>
            <Money
              value={data.from.balance_available - reason.fee}
              currency={tagCurrency}
            />
          </span>
        </div>
        <div className={'flex'}>
          <span className={'w-40'}>{t('Expiry-date')}:</span>
          <span className={'font-bold'}>
            {format(
              parse(data.from.tokenNewExpiryDate, 'yyyy-MM-dd', new Date()),
              'dd/MM/yyyy',
            )}
          </span>
        </div>
        <div className={'flex space-x-3'}>
          <button
            onClick={() => setPage(1)}
            className={cn(
              'mt-6 rounded-md bg-gfPeriwinkle py-3 px-4 transition w-1/4',
              !reason || status.status === 'loading'
                ? 'opacity-50 pointer-events-none'
                : '',
            )}
          >
            {t('Back')}
          </button>
          <button
            onClick={handleConfirmTransfer}
            className={cn(
              'mt-6 rounded-md bg-gfCoral py-3 px-4 transition w-3/4',
              !reason || status.status === 'loading'
                ? 'opacity-50 pointer-events-none'
                : '',
            )}
          >
            {t(status.status === 'loading' ? 'Loading' : 'Confirm')}
          </button>
        </div>
        {status.status === 'error' && (
          <FailAlert className={'mt-4'} message={status.message} />
        )}
      </div>
    );
  };

  const parsedOptions = useMemo(
    () =>
      selectedTag.transfer_fee.replacement_reasons.map(({ reason, fee }) => {
        const rootTranslationPath = 'card-replacements.reasons.';
        return {
          label: t(rootTranslationPath + reason),
          value: {
            value: reason,
            fee,
          },
        };
      }),
    [selectedTag, t],
  );

  const ReasonSelector = () => {
    return (
      <div>
        <RadioField
          options={parsedOptions}
          value={reason}
          onChange={setReason}
        />
        <button
          onClick={() => {
            setPage(2);
          }}
          className={cn(
            'mt-6 rounded-md bg-gfCoral w-full py-4 px-4 transition',
            reason ? '' : 'opacity-50 pointer-events-none',
          )}
        >
          {t('Next')}
        </button>
      </div>
    );
  };

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={() => {
        setPage(1);
        onClose();
      }}
    >
      <>
        <header className={'border-gray-200 relative mb-5'}>
          <div className={'flex justify-between items-center'}>
            <h1 className={'uppercase font-bold text-md'}>
              {page === 1 &&
                t('card-replacements.transfer-fee.reason-for-transfer')}
              {page === 2 &&
                t('card-replacements.transfer-fee.replacement-confirmation')}
            </h1>
          </div>
        </header>
        {page === 1 && <ReasonSelector />}
        {page === 2 && <ConfirmationMessage />}
      </>
    </DefaultModal>
  );
}
